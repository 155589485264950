import React, { useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import { Text, ActivityIndicator } from 'react-native-paper';
import useNavigation from '../hooks/useNavigation';
import { Linking } from 'expo';

export default () => {
  const navigation = useNavigation();

  const handleIncomingLink = ({ url }) => {
    console.log(url);
    let { path, queryParams } = Linking.parse(url);
    navigation.navigate(path, queryParams);
  };

  useEffect(() => {
    Linking.getInitialURL().then(url => handleIncomingLink({ url }));
    Linking.addEventListener('url', handleIncomingLink);
    return Linking.removeEventListener('url', handleIncomingLink);
  }, []);

  return (
    <View style={styles.container}>
      <ActivityIndicator />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center'
  }
});
