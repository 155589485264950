import React from 'react';
import 'react-native-gesture-handler';
import { Platform } from 'react-native';
import { Provider as PaperProvider } from 'react-native-paper';
import { createSwitchNavigator, createAppContainer } from 'react-navigation';
import { createStackNavigator } from 'react-navigation-stack';
import { createBrowserApp } from '@react-navigation/web';

import Fallback from './screens/Fallback';
import Event from './screens/Event';
import Exhibit from './screens/Exhibit';

import { Linking } from 'expo';

const routes = {
  Fallback: {
    screen: Fallback,
    path: ''
  },
  Event: {
    screen: Event,
    path: 'event/:eventId'
  },
  Exhibit: {
    screen: Exhibit,
    path: 'exhibit/:exhibitId'
  }
};

const Navigator =
  Platform.OS === 'web'
    ? createSwitchNavigator(routes)
    : createStackNavigator(routes);
const NavigationWrapper =
  Platform.OS === 'web'
    ? createBrowserApp(Navigator)
    : createAppContainer(Navigator);

const prefix = Linking.makeUrl('/');

export default function App() {
  return (
    <PaperProvider>
      <NavigationWrapper uriPrefix={prefix} />
    </PaperProvider>
  );
}
