import React, { ReactChild } from 'react';
import { SafeAreaView, StyleSheet, View } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';

interface Props {
  loading?: boolean;
  children: ReactChild;
}

export default ({ children, loading }: Props) => {
  return (
    <SafeAreaView style={{flex: 1}}>
      <View style={loading && styles.loading}>
        {loading ? <ActivityIndicator /> : children}
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  loading: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center'
  }
});
