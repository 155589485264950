import React from 'react';
import { View, StyleSheet } from 'react-native';
import { screenWidth, screenHeight } from '../utils/screenSize';
import LazyImage from '../components/LazyImage';
import { Exhibit } from '../types/Event';
import SideSwipe from 'react-native-sideswipe';
import { Image } from '../types';

const contentOffset = (screenWidth - screenWidth * 0.9) / 2;

const Gallery = ({
  index,
  onIndexChange,
  images
}: {
  index: number;
  onIndexChange(index: number): void;
  images: Image[];
}) => {
  return (
    <SideSwipe
      index={index}
      itemWidth={screenWidth * 0.9}
      style={{ width: screenWidth }}
      data={images}
      contentOffset={contentOffset}
      onIndexChange={onIndexChange}
      renderItem={({
        itemIndex,
        currentIndex,
        item,
        animatedValue
      }) => {
        return (
          <View style={styles.galleryContainer}>
            <View style={styles.galleryInnerContainer}>
              <LazyImage url={item.url} />
            </View>
          </View>
        );
      }}
    />
  );
};

const styles = StyleSheet.create({
  galleryContainer: {
    height: screenHeight * 0.5,
    width: screenWidth * 0.9,
    marginVertical: 10,
    paddingHorizontal: 5
  },
  galleryInnerContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: '#dddddd',
    borderWidth: 1,
    borderRadius: 5
  }
});

export default Gallery;