import queryString from 'query-string';
const baseUrl = 'https://api-v2.eventbinder.app';
// const baseUrl = 'http://localhost:8001';

export const get = async (
  path: string,
  query?: {
    [key: string]: any;
  }
) => {
  try {
    const queryStr = query && queryString.stringify(query);
    const res = await fetch(
      `${baseUrl}${path}${queryStr ? '?' + queryStr : ''}`,
      {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrer: 'no-referrer'
      }
    );
    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      return undefined;
    }
  } catch (error) {
    return undefined;
  }
};

export const post = async (
  path: string,
  body?: {
    [key: string]: any;
  }
) => {
  try {
    const res = await fetch(`${baseUrl}${path}`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      ...(body && {
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      }),
      redirect: 'follow',
      referrer: 'no-referrer'
    });
    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      return undefined;
    }
  } catch (error) {
    return undefined;
  }
};

export const put = async (
  path: string,
  body: {
    [key: string]: any;
  }
) => {
  try {
    const res = await fetch(`${baseUrl}${path}`, {
      method: 'PUT',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body),
      redirect: 'follow',
      referrer: 'no-referrer'
    });
    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      return undefined;
    }
  } catch (error) {
    return undefined;
  }
};

export const del = async (
  path: string,
  query: {
    [key: string]: any;
  }
) => {
  try {
    const queryStr = queryString.stringify(query);
    const res = await fetch(`${baseUrl}${path}?${queryStr}`, {
      method: 'PUT',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow',
      referrer: 'no-referrer'
    });
    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      return undefined;
    }
  } catch (error) {
    return undefined;
  }
};
