import Event, { Exhibit } from '../types/Event';
import sleep from '../utils/sleep';
import cuid from 'cuid';
import Company from '../types/Company';
import { Sex, SocialMediaChannel } from '../types';
import { get, post } from '../utils/request';

export class EventApi {
  static async get(eventId) {
    const data = await get(`/event/${eventId}`);
    console.log(data.event);
    return data.event;
  }
}

export class ExhibitApi {
  static async get(exhibitId): Promise<Exhibit> {
    const data = await get(`/exhibit/${exhibitId}`);
    console.log(data.exhibit);
    return {
      ...data.exhibit,
      likes: data.exhibit.likes.length,
      rates: data.exhibit.rates.length,
      shares: data.exhibit.shares.length,
    };
  }

  static async like(exhibitId): Promise<boolean> {
    await post(`/exhibit/${exhibitId}/like`);
    return true;
  }

  static async share(exhibitId): Promise<boolean> {
    await post(`/exhibit/${exhibitId}/share`);
    return true;
  }
}
