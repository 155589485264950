import AsyncStorage from '@callstack/async-storage';

export const getAllKeys = async () => {
  const items = await AsyncStorage.getAllKeys();
  console.log(items);
};

export const getVisitedEventCount = async eventId => {
  const visitedEventCount = await AsyncStorage.getItem(`count-${eventId}`);
  if (visitedEventCount == null) {
    await AsyncStorage.setItem(`count-${eventId}`, 0);
  }
  return Number(visitedEventCount);
};

export const addVisitedEventCount = async eventId => {
  const visitedEventCount = await getVisitedEventCount(eventId);
  await AsyncStorage.setItem(`count-${eventId}`, visitedEventCount + 1);
};

export const getVisitedEvents = async () => {
  const current = await AsyncStorage.getItem('visitedEvents');
  if (current == null) {
    await AsyncStorage.setItem('visitedEvents', '');
  }
  const currentVisitedEvents = current.split(',') as string[];
  return currentVisitedEvents;
};

export const addVisitedEvent = async eventId => {
  const visitedEvents = [...(await getVisitedEvents())];
  if (visitedEvents.includes(eventId)) {
    return;
  }
  visitedEvents.push(eventId);
  await AsyncStorage.setItem('visitedEvents', visitedEvents.join(','));
};

export const getLikedExhibits = async () => {
  const current = await AsyncStorage.getItem('likedExhibits');
  if (current == null) {
    await AsyncStorage.setItem('likedExhibits', '');
  }
  const currentLikedExhibits = current.split(',') as string[];
  return currentLikedExhibits;
};

export const addLikedExhibit = async exhibitId => {
  const likedExhibits = [...(await getLikedExhibits())];
  likedExhibits.push(exhibitId);
  await AsyncStorage.setItem('likedExhibits', likedExhibits.join(','));
};

export const isExhibitLiked = async exhibitId => {
  const likedExhibits = await getLikedExhibits();
  return likedExhibits.includes(exhibitId);
};
