import React, { useEffect, useState } from 'react';
import { Image } from 'react-native';
const icon = require('../assets/EB-logo-word-only.png');

export default ({ size = 30 }) => {
  const width = 994;
  const height = 141;
  const [dimension, setDimension] = useState<{
    width: number;
    height: number;
  }>({
    width: 0,
    height: 0
  });
  useEffect(() => {
    const ratio = width / height;
    setDimension({
      width: ratio * size,
      height: size
    });
  }, [size]);

  return <Image style={{ ...dimension }} source={icon} />;
};
