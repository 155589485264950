import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Image, Clipboard, Alert } from 'react-native';
import { Text, Portal, Modal, Snackbar } from 'react-native-paper';
import useNavigation from '../hooks/useNavigation';
import { ExhibitApi, EventApi } from '../services/api';
import Event, { Exhibit } from '../types/Event';
import RootView from '../components/RootView';
import Gallery from '../components/Gallery';
import EbIcon from '../components/EbIcon';
import { screenWidth, screenHeight } from '../utils/screenSize';
import { TouchableOpacity } from 'react-native-gesture-handler';
import {
  Ionicons,
  AntDesign,
  MaterialCommunityIcons
} from '@expo/vector-icons';
import { Linking } from 'expo';
import {
  addVisitedEvent,
  addVisitedEventCount,
  addLikedExhibit,
  isExhibitLiked
} from '../utils/asyncStorage';

const Header = ({ title, subtitle }: { title: string; subtitle: string }) => {
  return (
    <View style={styles.header}>
      <Text style={styles.headerTitle}>{title}</Text>
      <Text style={styles.headerSubtitle}>{subtitle}</Text>
    </View>
  );
};

const Footer = () => {
  return (
    <View style={styles.footer}>
      <View style={styles.footerTextContainer}>
        <Text style={styles.footerText}>Designd and powered by</Text>
        <EbIcon size={10} />
      </View>
    </View>
  );
};

const ExhibitActions = ({
  exhibitId,
  onActionPress
}: {
  exhibitId: string;
  onActionPress(action: { type: 'description' | 'share' | 'followUs' }): void;
}) => {
  const [isLiked, setIsliked] = useState(false);
  useEffect(() => {
    isExhibitLiked(exhibitId).then(liked => {
      setIsliked(liked);
    });
  }, [exhibitId]);
  return (
    <>
      <View
        style={[
          styles.actionContainer,
          {
            marginBottom: 5
          }
        ]}
      >
        <TouchableOpacity
          style={[
            styles.action,
            {
              marginRight: 5
            }
          ]}
          onPress={() => {
            if (!isLiked) {
              ExhibitApi.like(exhibitId).then(_ => {
                addLikedExhibit(exhibitId);
                setIsliked(true);
              });
            }
          }}
        >
          {isLiked ? (
            // <AntDesign name="like1" size={30} color="#000" />
            <Image
              style={{ width: 30, height: 30 }}
              source={require('../assets/fb-like-grey.png')}
            />
          ) : (
            // <AntDesign name="like2" size={30} color="#000" />
            <Image
              style={{ width: 30, height: 30 }}
              source={require('../assets/fb-like-outlined.png')}
            />
          )}
          <Text style={styles.actionText}>Like</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[
            styles.action,
            {
              marginLeft: 5
            }
          ]}
          onPress={() =>
            onActionPress({
              type: 'share'
            })
          }
        >
          <MaterialCommunityIcons name="share-variant" size={30} />
          <Text style={styles.actionText}>Share</Text>
        </TouchableOpacity>
      </View>
    </>
  );
};

const Actions = ({
  onActionPress
}: {
  onActionPress(action: { type: 'description' | 'share' | 'followUs' }): void;
}) => {
  return (
    <>
      <View
        style={[
          styles.actionContainer,
          {
            marginTop: 5
          }
        ]}
      >
        <TouchableOpacity
          style={[
            styles.action,
            {
              marginRight: 5
            }
          ]}
          onPress={() =>
            onActionPress({
              type: 'description'
            })
          }
        >
          <Text style={styles.actionText}>The story</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[
            styles.action,
            {
              marginLeft: 5
            }
          ]}
          onPress={() =>
            onActionPress({
              type: 'followUs'
            })
          }
        >
          <Text style={styles.actionText}>Follow us on</Text>
        </TouchableOpacity>
      </View>
    </>
  );
};

const PopUp = ({
  type,
  content,
  onClose,
  onShare
}: {
  type: 'description' | 'share' | 'binder' | 'followUs';
  content: any;
  onClose(): void;
  onShare?(): void;
}) => {
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
  useEffect(() => {
    if (isSnackbarVisible) {
      setTimeout(() => {
        setIsSnackbarVisible(false);
      }, 2000);
    }
  }, [isSnackbarVisible]);
  return (
    <View style={styles.popUpContainer}>
      <TouchableOpacity onPress={onClose} style={styles.popUpHeaderContainer}>
        <View style={styles.popUpHeaderIcon}>
          <Ionicons name="ios-arrow-back" size={25} color="#000" />
        </View>
        <Text style={styles.popUpHeaderBackText}>Back</Text>
      </TouchableOpacity>
      {type === 'description' && (
        <>
          <Text style={styles.descriptionTitle}>The Story</Text>
          <Text style={styles.descriptionText}>
            {content && content.description}
          </Text>
        </>
      )}
      {type === 'share' && (
        <View style={styles.shareConatiner}>
          <Text style={styles.shareTitle}>Share to your friends</Text>
          <View style={styles.shareMainContainer}>
            <TouchableOpacity
              onPress={() => {
                onShare && onShare();
                Linking.openURL(
                  'https://www.facebook.com/sharer/sharer.php?u=' +
                    encodeURIComponent(
                      `https://i.eventbinder.app/exhibit/${content.exhibitId}`
                    )
                );
              }}
              style={styles.shareMediaContainer}
            >
              <AntDesign name="facebook-square" size={30} />
              <Text style={styles.shareMediaText}>Facebook</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                onShare && onShare();
                Linking.openURL(
                  'fb-messenger://share?link=' +
                    encodeURIComponent(
                      `https://i.eventbinder.app/exhibit/${content.exhibitId}`
                    )
                );
              }}
              style={styles.shareMediaContainer}
            >
              <MaterialCommunityIcons name="facebook-messenger" size={30} />
              <Text style={styles.shareMediaText}>Messenager</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                onShare && onShare();
                Linking.openURL(
                  `whatsapp://send?text=https://i.eventbinder.app/exhibit/${content.exhibitId}}`
                );
              }}
              style={styles.shareMediaContainer}
            >
              <MaterialCommunityIcons name="whatsapp" size={30} />
              <Text style={styles.shareMediaText}>WhatsApp</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                Clipboard.setString(
                  `https://i.eventbinder.app/exhibit/${content.exhibitId}`
                );
                setIsSnackbarVisible(true);
              }}
              style={styles.shareMediaContainer}
            >
              <MaterialCommunityIcons name="content-copy" size={30} />
              <Text style={styles.shareMediaText}>Copy Link</Text>
            </TouchableOpacity>
          </View>
        </View>
      )}
      {type === 'followUs' && (
        <View style={styles.shareConatiner}>
          <Text style={styles.shareTitle}>Follow us on</Text>
          <View style={styles.shareMainContainer}>
            <TouchableOpacity
              onPress={() => {
                Linking.openURL('fb://profile/363604420498153');
              }}
              style={styles.shareMediaContainer}
            >
              <AntDesign name="facebook-square" size={30} />
              <Text style={styles.shareMediaText}>Facebook</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                Linking.openURL(`instagram://user?username=uabbhk`);
              }}
              style={styles.shareMediaContainer}
            >
              <AntDesign name="instagram" size={30} />
              <Text style={styles.shareMediaText}>Instagram</Text>
            </TouchableOpacity>
          </View>
        </View>
      )}
      <Snackbar
        style={{
          width: screenWidth - 40 - 16,
        }}
        visible={isSnackbarVisible}
        onDismiss={() => setIsSnackbarVisible(false)}
        action={{
          label: 'OKAY',
          onPress: () => setIsSnackbarVisible(false)
        }}
      >
        Copied!
      </Snackbar>
    </View>
  );
};

export default () => {
  const navigation = useNavigation();
  const [exhibit, setExhibit] = useState<Exhibit>();
  const [event, setEvent] = useState<Event>();
  const [loading, setLoading] = useState(true);
  const [galleryIndex, setGalleryIndex] = useState(0);
  const [popUpType, setPopUpType] = useState();
  const [popUpContent, setPopUpContent] = useState();
  useEffect(() => {
    const eventId = 'ck4az31gu00840791gbane8sm';
    addVisitedEvent(eventId);
    addVisitedEventCount(eventId);
    const { exhibitId } = navigation.state.params;
    EventApi.get(eventId)
      .then(ev => {
        setEvent(ev);
        setLoading(false);
      })
      .catch(err => {
        console.error(err);
        setLoading(false);
      });
    ExhibitApi.get(exhibitId)
      .then(exhibit => {
        setExhibit(exhibit);
        setLoading(false);
      })
      .catch(err => {
        console.error(err);
        setLoading(false);
      });
  }, []);

  return (
    <Portal>
      <RootView loading={loading}>
        {exhibit && event && !loading && (
          <View style={styles.container}>
            <Gallery
              index={galleryIndex}
              onIndexChange={index => setGalleryIndex(index)}
              images={exhibit.images}
            />
            <Header title={exhibit.name} subtitle={event.name} />
            <ExhibitActions
              exhibitId={navigation.state.params.exhibitId}
              onActionPress={({ type }) => {
                console.log(type);
                setPopUpType(type);
                if (type === 'description') {
                  setPopUpContent({
                    description: exhibit.description
                  });
                } else if (type === 'share') {
                  setPopUpContent({
                    exhibitId: navigation.state.params.exhibitId
                  });
                }
              }}
            />
            <Actions
              onActionPress={({ type }) => {
                setPopUpType(type);
                if (type === 'description') {
                  setPopUpContent({
                    description: exhibit.description
                  });
                } else if (type === 'share') {
                  setPopUpContent({
                    exhibitId: navigation.state.params.exhibitId
                  });
                }
              }}
            />
            <Footer />
            <Modal
              contentContainerStyle={styles.descriptionModal}
              visible={popUpType}
              onDismiss={() => setPopUpType(null)}
              dismissable
            >
              <PopUp
                type={popUpType}
                content={popUpContent}
                onClose={() => {
                  setPopUpType(null);
                  setPopUpContent(null);
                }}
                onShare={() => {
                  ExhibitApi.share(navigation.state.params.exhibitId);
                  // getAllKeys();
                }}
              />
            </Modal>
          </View>
        )}
      </RootView>
    </Portal>
  );
};

const styles = StyleSheet.create({
  container: {
    height: screenHeight,
    backgroundColor: '#fff',
    alignItems: 'center'
  },
  header: {
    height: screenHeight * 0.15,
    width: screenWidth * 0.9
  },
  headerTitle: {
    fontSize: screenHeight * 0.03,
    fontWeight: 'bold'
  },
  headerSubtitle: {
    fontSize: screenHeight * 0.02,
    alignSelf: 'flex-end',
    textAlign: 'right'
  },
  footer: {
    height: screenHeight * 0.1 - 20,
    width: screenWidth * 0.9,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
    // marginTop: 'auto'
  },
  footerTextContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center'
  },
  footerText: {
    fontSize: 12,
    marginRight: 2
  },
  actionContainer: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    width: screenWidth * 0.9,
    height: (screenHeight * 0.25) / 2 - 5
  },
  action: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: '#000000',
    borderWidth: 2,
    borderRadius: 10
  },
  actionText: {
    fontSize: screenHeight * 0.025
  },
  descriptionModal: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  popUpContainer: {
    width: screenWidth,
    height: screenHeight,
    backgroundColor: '#fff',
    padding: 20
  },
  popUpHeaderContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20
  },
  popUpHeaderIcon: {
    marginRight: 10
  },
  popUpHeaderBackText: {
    fontSize: 20
  },
  descriptionTitle: {
    fontSize: 30,
    marginBottom: 20
  },
  descriptionText: {
    fontSize: 20,
    lineHeight: 26,
    overflowY: 'scroll',
    overflowX: 'hidden'
  },
  shareConatiner: {
    flex: 1,
    alignItems: 'center'
  },
  shareTitle: {
    fontSize: 30,
    fontWeight: 'bold',
    marginBottom: 20
  },
  shareMainContainer: {
    flex: 1,
    alignItems: 'center'
  },
  shareMediaContainer: {
    width: screenWidth * 0.75,
    height: screenWidth * 0.75 * 0.2,
    borderRadius: 10,
    borderWidth: 2,
    borderColor: '#000',
    paddingHorizontal: 30,
    paddingVertical: 10,
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 20
  },
  shareMediaText: {
    marginLeft: 10
  }
});
