import React, { useState, useEffect } from 'react';
import { Image, ActivityIndicator } from 'react-native';

export default ({ url }) => {
  const [loading, setLoading] = useState(true);
  const [image, setImage] = useState<{
    url: string;
    width: number;
    height: number;
  }>();
  useEffect(() => {
    url &&
      Image.getSize(
        url,
        (width, height) => {
          setImage({
            url,
            width,
            height
          });
          setLoading(false);
        },
        error => {
          console.error(error);
          setLoading(true);
        }
      );
  }, [url]);
  return !loading && image ? (
    <Image
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0
      }}
      source={{ uri: image.url }}
      resizeMode="contain"
    />
  ) : (
    <ActivityIndicator />
  );
};
